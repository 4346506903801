import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/composables/notification/useAlert'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import useSweetAlertNotification from './notification/useSweetAlert'

export default function useUsers() {
  const { successToast } = useAlertNotification()
  const { errorSweetAlert } = useSweetAlertNotification()

  const user = ref([])
  const loader = ref(false)
  const userProcess = ref(false)
  const userSuccess = ref(false)
  const userCheck = ref(false)

  const users = ref([])
  const currentUser = ref([])
  const errors = ref('')

  const getAllUsers = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-users')
      if (response.data.success === true) {
        loader.value = false
        users.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const changeAccountStatus = async id => {
    try {
      errors.value = ''
      userProcess.value = true
      const response = await axiosClient.patch(`/suspend-user/${id}`)
      if (response.data.success === true) {
        if (response.data.message === 'Compte suspendu avec succès') {
          successToast('Suspension de compte', response.data.message)
        } else {
          successToast('Activation de compte', response.data.message)
        }
        userProcess.value = true
        userSuccess.value = true
        loader.value = false
        await getAllUsers()
      }
    } catch (error) {
      loader.value = false
      userProcess.value = false
      loader.value = false
      errors.value = ''
      errorSweetAlert('Oups! Erreur', error.response.data.message)
    }
  }

  const getUserById = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-user-by-id/${id}`)
      if (response.data.success === true) {
        user.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }
  const findUserByEmailOrMatricule = async formSearch => {
    try {
      loader.value = true
      const response = await axiosClient.post('/find-user-by-email-or-matricule', formSearch)
      if (response.data.success === true) {
        currentUser.value = response.data.data
        user.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false

      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
    }
  }
  const getUserByMatricule = async matricule => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-user-by-matricule/${matricule}`)
      if (response.data.success === true) {
        user.value = response.data.data
        loader.value = false
        userCheck.value = true
      }
    } catch (error) {
      loader.value = false
      userCheck.value = false
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
    }
  }

  const createUser = async data => {
    try {
      errors.value = ''
      userProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-user', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        userSuccess.value = true
        userProcess.value = false
        user.value = response.data.data
        await getAllUsers()
      }
    } catch (error) {
      userProcess.value = false
      userSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const createOrUpdateUserByAdminSyndic = async data => {
    try {
      errors.value = ''
      userProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/add-user-in-copropriete', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        userSuccess.value = true
        userProcess.value = false
        user.value = response.data.data
        await getAllUsers()
      }
    } catch (error) {
      userProcess.value = false
      userSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const updateUser = async data => {
    try {
      errors.value = ''
      userProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-user/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Modification', response.data.message)
        userSuccess.value = true
        userProcess.value = false
        user.value = response.data.data
        await getAllUsers()
      }
    } catch (error) {
      userProcess.value = false
      userSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const removeUserByAdminSyndicInCopropriete = async (userId, coproprieteId) => {
    try {
      errors.value = ''
      userProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.delete(`/remove-user-in-copropriete/${userId}/${coproprieteId}`)
      if (response.data.success === true) {
        successToast('Suppression', response.data.message)
        userSuccess.value = true
        userProcess.value = false
        user.value = response.data.data
        await getAllUsers()
      }
    } catch (error) {
      userProcess.value = false
      userSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  // Rediriger l'utilisateur sur son dashboard selon son rôle
  const redirectUser = async role => {
    switch (role) {
      case 'Super administrateur':
        router.push({ name: 'espace-super-admin.dashboard' })
        break
      case 'Administrateur syndic':
        router.push({ name: 'espace-admin-syndic.dashboard' })
        break
      case 'Gestionnaire financier':
        router.push({ name: 'dashboard' })
        break
      case 'Agent immobilier':
        router.push({ name: 'dashboard' })
        break
      case 'Copropriétaire':
        router.push({ name: 'espace-coproprietaire.dashboard' })
        break
      case 'Locataire':
        router.push({ name: 'espace-locataire.dashboard' })
        break
      case 'Planificateur de tâches':
        router.push({ name: 'espace-planificateur.dashboard' })
        break
      case 'Contrôleur de service':
        router.push({ name: 'dashboard' })
        break
      case 'Prestataire de service':
        router.push({ name: 'espace-prestataire-de-service.dashboard' })
        break
      default:
        break
    }
  }

  return {
    errors,
    user,
    users,
    userProcess,
    getUserByMatricule,
    userCheck,
    findUserByEmailOrMatricule,
    redirectUser,
    changeAccountStatus,
    loader,
    userSuccess,
    getAllUsers,
    getUserById,
    createUser,
    updateUser,
    currentUser,
    createOrUpdateUserByAdminSyndic,
    removeUserByAdminSyndicInCopropriete,

  }
}
