import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
// eslint-disable-next-line import/no-cycle

export default function useGestionCoproprietes() {
  const loader = ref(false)

  const gestionCoproprietes = ref([])
  const coproprietaires = ref([])
  const staffs = ref([])
  const errors = ref('')

  const getUserCoproprietesWithRolesInEachCoproprietes = async userId => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-user-with-coproprietes-and-roles/${userId}`)
      if (response.data.success === true) {
        loader.value = false
        gestionCoproprietes.value = response.data.data.user
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getCoproprieteWithUsersAndRolesInEachCoproprietes = async coproprieteId => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-copropriete-with-users-and-roles/${coproprieteId}`)
      if (response.data.success === true) {
        loader.value = false
        gestionCoproprietes.value = response.data.data.copropriete
      }
    } catch (error) {
      loader.value = false
    }
  }

  const getCoproprieteCoproprietaires = async coproprieteId => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-coproprietaire-in-copropriete/${coproprieteId}`)
      if (response.data.success === true) {
        loader.value = false
        coproprietaires.value = response.data.data.copropriete
      }
    } catch (error) {
      loader.value = false
    }
  }

  const getCoproprieteStaffs = async coproprieteId => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-staff-copropriete/${coproprieteId}`)
      if (response.data.success === true) {
        loader.value = false
        staffs.value = response.data.data.copropriete
      }
    } catch (error) {
      loader.value = false
    }
  }

  return {
    errors,
    gestionCoproprietes,
    loader,
    coproprietaires,
    staffs,
    getCoproprieteCoproprietaires,
    getCoproprieteStaffs,
    getUserCoproprietesWithRolesInEachCoproprietes,
    getCoproprieteWithUsersAndRolesInEachCoproprietes,

  }
}
