import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/composables/notification/useAlert'
import useSweetAlertNotification from '@/composables/notification/useSweetAlert'
// eslint-disable-next-line import/no-cycle

export default function useRoles() {
  const { successToast } = useAlertNotification()
  const { errorSweetAlert } = useSweetAlertNotification()

  const role = ref([])
  const loader = ref(false)
  const roleProcess = ref(false)
  const roleSuccess = ref(false)

  const roles = ref([])
  const errors = ref('')

  const getAllRoles = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-roles')
      if (response.data.success === true) {
        loader.value = false
        roles.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }
  const getRolesExceptById = async data => {
    try {
      loader.value = true
      const response = await axiosClient.post('/get-roles-excepts-by-id', data)
      if (response.data.success === true) {
        loader.value = false
        roles.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  const getRoleById = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-role-by-id/${id}`)
      if (response.data.success === true) {
        role.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }

  const createRole = async data => {
    try {
      errors.value = ''
      roleProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-role', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        roleSuccess.value = true
        roleProcess.value = false
        role.value = response.data.data
        await getAllRoles()
      }
    } catch (error) {
      roleProcess.value = false
      roleSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const updateRole = async data => {
    try {
      errors.value = ''
      roleProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-role/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Modification', response.data.message)

        roleSuccess.value = true
        roleProcess.value = false
        role.value = response.data.data
        await getAllRoles()
      }
    } catch (error) {
      roleProcess.value = false
      roleSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  return {
    errors,
    role,
    roles,
    roleProcess,
    loader,
    roleSuccess,
    getAllRoles,
    getRoleById,
    createRole,
    updateRole,
    getRolesExceptById,

  }
}
